import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import getUserData from "../utils/userDataService";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import "./ProductInfo.css";

const ProductInfo = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [allowUser, setAllowUser] = useState(false);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("UPI");
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [orderId, setOrderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [tagThree, setTagThree] = useState("");
  const [balance, setBalance] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }
  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    getProduct();
  }, [params?.name]);

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      setCheckLoading(true);
      const object = {
        userid: userId,
        zoneid: zoneId,
        apiName: product?.apiName,
      };
      const res = await axios.post("/api/payment/get-role", object, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setCheckLoading(false);
        if (product?.user === res.data.user) {
          setAllowUser(true);
        } else {
          setAllowUser(false);
        }
      } else {
        message.error(res.data.message);
        setCheckLoading(false);
      }
    } catch (error) {
      console.log(error);
      setCheckLoading(false);
    }
  }

  function checkPlaceOrder(e) {
    if (product?.api === "no") {
      if (userId === "") {
        return message.error("Some Fields are missing");
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
      if (product?.gameName === "Clash Of Clans") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
      if (product?.gameName === "Genshin Impact") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    } else if (product?.apiName === "moogold") {
      // mlbb & genshin
      if (product?.gameName === "428075" || product?.gameName === "15145") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
      if (product?.gameName === "13465") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
    } else {
      if (userId === "") {
        return message.error("Enter User ID");
      }
      if (zoneId === "") {
        return message.error("Enter Zone ID");
      }
      if (playerCheck === null) {
        return message.error("Check Your Player Name");
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (mode === "UPI") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else if (product?.apiName === "smileOne") {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (product?.apiName === "moogold") {
        if (mode === "UPI") {
          handleMoogoldUpiOrder(e);
        } else {
          handleMoogoldWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://arisato.in/api/yokcash/check-yokcash-upi-order?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "%" +
          zoneId.trim() +
          "%" +
          productId +
          "%" +
          product?.name,
      };

      setLoader(true);
      const response = await axios.post(
        "/api/payment/create-api-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  async function handleYokcashWalletOrder(e) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "%" +
          zoneId.trim() +
          "%" +
          productId +
          "%" +
          product?.name,
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post(
        "/api/yokcash/place-yokcash-from-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/user-dashboard");
        setLoading(false);
        setLoader(false);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://arisato.in/api/smile/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoader(true);
      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      setLoading(true);
      setLoader(true);
      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setLoader(false);
        setLoading(false);
        navigate("/user-dashboard");
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://arisato.in/api/moogold/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoader(true);
      const response = await axios.post("/api/moogold/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: product?.api,
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      setLoader(true);
      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/user-dashboard");
        setLoading(false);
        setLoader(false);
      } else {
        setLoading(false);
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }

  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://arisato.in/api/manual/status?orderId=${orderId}`,
        txn_note: userId + "@" + zoneId + "@" + tagThree + "@" + amount,
      };
      setLoader(true);
      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setLoader(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        tagThree: tagThree,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      setLoading(true);
      setLoader(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoading(false);
        setLoader(false);
        message.success(res.data.message);
        navigate("/orders");
      } else {
        message.error(res.data.message);
        setLoading(false);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="p-info-container area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="pro-img">
          <img src={`https://arisato.in/${product?.image}`} alt="" />
        </div>
        <div className="pro-content">
          <h2>{product?.name}</h2>
          <h6>{product?.tagLine}</h6>
        </div>
      </div>
      <div className="package-details">
        <div className="package-container">
          {product?.cost?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  setAmount(item.amount);
                  setPriceAndId(item.amount);
                }}
                key={index}
                className={`amount ${amount === item?.amount && "active"}`}
              >
                <div className="pack">
                  <span>
                    <small>{item?.amount}</small>
                  </span>
                </div>
                <div className="price">
                  <b>
                    ₹
                    {user?.reseller === "yes"
                      ? parseFloat(item?.resPrice).toFixed(2)
                      : parseFloat(item?.price).toFixed(2)}
                  </b>
                </div>
              </div>
            );
          })}
        </div>
        {/* ================================= FIELDS  */}
        {/* ================================= FIELDS  */}
        {/* ================================= FIELDS  */}
        <div className="order-info">
          <div className="pack-info">
            <span>Order Information</span>
            {product?.fields === "1" ? (
              <div className="d-flex align-items-center">
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tagOne}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              </div>
            ) : product?.fields === "2" ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder={`${product?.tagOne}`}
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder={`${product?.tagTwo}`}
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                {checkLoading && (
                  <>
                    <div
                      class="spinner-border spinner-border-sm me-2 mt-2"
                      role="status"
                    >
                      <span class="visually-hidden"></span>
                    </div>
                    Checking Username
                  </>
                )}
                <div>
                  <span className="text-success">
                    {playerCheck && "Username: " + playerCheck}
                  </span>
                </div>
              </>
            ) : product?.fields === "3" ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder={`${product?.tagOne}`}
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <select
                  name="zoneId"
                  className="form-select player-tag text-dark"
                  onChange={(e) => setZoneId(e.target.value)}
                >
                  <option value="">Select Server</option>
                  {product?.tagTwo?.split("+")?.map((item, index) => {
                    return <option value={item}>{item}</option>;
                  })}
                </select>
                {checkLoading && (
                  <>
                    <div
                      class="spinner-border spinner-border-sm me-2 mt-2"
                      role="status"
                    >
                      <span class="visually-hidden"></span>
                    </div>
                    Checking Username
                  </>
                )}
                <div>
                  <span className="text-success">
                    {playerCheck && "Username: " + playerCheck}
                  </span>
                </div>
              </>
            ) : (
              product?.fields === "4" && (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder={`${product?.tagOne}`}
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder={`${product?.tagTwo}`}
                      onChange={(e) => setZoneId(e.target.value)}
                      value={zoneId}
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="tagThree"
                      placeholder={`${product?.tagThree}`}
                      onChange={(e) => setTagThree(e.target.value)}
                      value={tagThree}
                    />
                  </div>
                  {checkLoading && (
                    <>
                      <div
                        class="spinner-border spinner-border-sm me-2 mt-2"
                        role="status"
                      >
                        <span class="visually-hidden"></span>
                      </div>
                      Checking Username
                    </>
                  )}
                  <div>
                    <span className="text-success">
                      {playerCheck && "Username: " + playerCheck}
                    </span>
                  </div>
                </>
              )
            )}
            {product?.playerCheckbtn === "yes" && (
              <button className="p-check-btn" onClick={handleCheckPlayer}>
                Validate Username
              </button>
            )}
          </div>

          <div className="mt-4 pack-info">
            <div className="title">
              <span>Select Payment Mode</span>
            </div>
            <div className="payment">
              <div
                onClick={() => setMode("UPI")}
                className={`upi ${mode === "UPI" && "active"}`}
              >
                {/* <img src={IMAGES.upi} alt="" /> */}
                <h4 className="m-0">UPI</h4>
              </div>
              <div
                onClick={() => setMode("WALLET")}
                className={`wallet ${mode === "WALLET" && "active"}`}
              >
                <div>
                  <AccountBalanceWalletIcon className="icon" />
                  <span className="ms-2">{user && <b>Rs. {balance}</b>}</span>
                </div>
                <h4 className="m-0">Wallet</h4>
              </div>
            </div>
          </div>

          <div className="mt-4 pack-info">
            <div className="title">
              <span>Total</span>
              <div className="price ">
                {selectedPrice !== null ? (
                  <h3 className="m-0 mt-3">
                    <b>Rs. {selectedPrice}</b>
                  </h3>
                ) : (
                  "Select an amount to see the price"
                )}
              </div>
            </div>
            {!user ? (
              <button
                className="p-check-btn"
                onClick={() => navigate("/login")}
              >
                Please Login First
              </button>
            ) : product?.stock === "No" ? (
              <button className="p-check-btn" style={{ opacity: "0.7" }}>
                Out of Stock
              </button>
            ) : product?.apiName === "moogold" &&
              (product?.gameName === "4427071" ||
                product?.gameName === "5177311") ? (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="p-check-btn"
              >
                Buy Now
                {loader && (
                  <div class="ms-2 spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            ) : product?.api === "no" ? (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="p-check-btn"
              >
                Buy Now
                {loader && (
                  <div class="ms-2 spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            ) : product?.gameName === "428075" ||
              product?.gameName === "5177311" ? (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="p-check-btn"
              >
                Buy Now
                {loader && (
                  <div class="ms-2 spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            ) : playerCheck === null ? (
              ""
            ) : product?.apiName === "smileOne" && !allowUser ? (
              <button disabled={true} className="p-check-btn">
                This ID is not allowed
              </button>
            ) : (
              <button
                disabled={loader}
                onClick={checkPlaceOrder}
                className="p-check-btn"
              >
                Buy Now
                {loader && (
                  <div class="ms-2 spinner-grow spinner-grow-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
        {/* ================================= FIELDS  */}
        {/* ================================= FIELDS  */}
        {/* ================================= FIELDS  */}

        <div className="pro-desc-container">
          <div className="pro-desc">
            <span>{product?.desc}</span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductInfo;
